import { React } from "react";
import "./About.css";
import about1 from "../../images/about_us_1.jpg";
import about2 from "../../images/about_us_2.jpg";

function About() {
  return (
    <>
      <section className="about-us">
        <h1 className="about-us-heading">About Us</h1>
        <p className="about-us-subtext">
          We are committed to not only providing quality products and services,
          but going above and beyond to ensure our customers are completely
          satisfied.
        </p>
        <div className="row gx-0">
          <div className="col-md-6 about-us-backdrop">
            <img src={about1} width="359" height="205" alt="" />
          </div>
          <div className="col-md-6 about-us-text">
            <div
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-once="true"
            >
              <p>
                Avinya Automation is comprised of engineering experts who focus
                on creating innovative solutions for complex engineering
                projects. We have accomplished much success with various
                projects. We strive for innovation and work meticulously in
                order to stay within budget while providing maximum value for
                our clients.
                <br />
                <br />
                We’ve been producing exceptional products at the best value. At
                Avinya Automation, we pride ourselves on phenomenal quality.
                From the initial concept to the final product, our attention to
                detail is second to none.
              </p>
            </div>
          </div>
          <div className="col-md-6 about-us-text order-2 order-md-1">
            <div
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-once="true"
            >
              <p>
                In an ever-evolving industry, Avinya Automation has set the bar
                for innovation and delivery.Our mission at Avinya Automation is
                to provide high-quality services for our valued clients. Our
                team goes above and beyond to cater to each project’s specific
                needs.
                <br />
                <br />
                Through open communication and remarkable service, we hope
                you’ll find what you’re looking for with our Automation. Explore
                our site and see for yourself - we love our work and we’re sure
                you will, too.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6 about-us-backdrop order-1 order-md-2">
            <img src={about2} width="359" height="205" alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
