import React, { useState } from "react";
import ProgressBar from "./ProgressBar";
import "./UploadForm.css";
import { useAuth } from "../firebase/AuthContext";

const UploadForm = () => {
  const { currentUser } = useAuth();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const types = ["image/png", "image/jpeg"];

  const handleChange = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError("");
    } else {
      setFile(null);
      setError("Please select an image file (png or jpg)");
    }
    // console.log(file);
    // console.log(error);
  };

  return (
    <form className={currentUser ? "upload-form" : "logged-out"}>
      <label className="upload-label">
        <input type="file" onChange={handleChange} />
        <span>+</span>
      </label>
      <div className="output">
        {error && <div className="error">{error}</div>}
        {file && <div>{file.name}</div>}
        {file && <ProgressBar file={file} setFile={setFile} />}
      </div>
    </form>
  );
};

export default UploadForm;
