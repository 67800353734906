import React from "react";
import Map from "../Map";
import "./ContactUs.css";

function ContactUs() {
  return (
    <>
      <section className="contact-us">
        <h1 className="contact-heading">Get in touch</h1>
        <p className="contact-subtext">
          Interested in placing an order or simply want to learn more about us?
        </p>
        <div className="contact-details">
          <p>
            Address:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://g.page/dmc-manufacturers?share"
            >
              Shop No. 3, Triveninagar, Talawade Road, Opp. to Mahanagar Bank,
              Pune - 411062 <br />
            </a>
          </p>
          <br />
          <p>
            Email:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:avinyaautomation01@gmail.com"
            >
              avinyaautomation01@gmail.com
            </a>
          </p>
          <br />
          <p>
            Contact:
            <a href="tel:9790722190"> 9790722190</a>
          </p>
        </div>

        <div className="map">
          <div
            data-aos="fade"
            data-aos-duration="1300"
            data-aos-delay="400"
            data-aos-once="true"
          >
            <Map />
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
