import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const centre = [18.669, 73.7856];

function Map() {
  return (
    <>
      <MapContainer center={[18.665, 73.7856]} zoom={14} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={centre}>
          <Popup>
            Avinya Automation <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/dir//DMC+Manufacturers,+Shop+no.3,+Talawade+Rd,+Triveni+Nagar,+Pimpri-Chinchwad,+Maharashtra+412114/@18.6689961,73.7834006,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3bc2b7e3b7ec525f:0xfc11126174acddcc!2m2!1d73.7856189!2d18.6689855!3e0"
            >
              Directions
            </a>
          </Popup>
        </Marker>
      </MapContainer>
    </>
  );
}

export default Map;
