import React from "react";
import { motion } from "framer-motion";
import "./Modal.css";
import { MdDelete } from "react-icons/md";
import { IconContext } from "react-icons";
import { projectFirestore } from "../firebase/config";
import { useAuth } from "../firebase/AuthContext";

const Modal = ({ setSelectedImg, selectedImg, deleteId, setDeleteId }) => {
  const { currentUser } = useAuth();

  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectedImg(null);
      setDeleteId(null);
    }
  };

  const handleDelete = () => {
    projectFirestore
      .collection("images")
      .doc(deleteId)
      .delete()
      .then(() => {
        setSelectedImg(null);
      });
  };

  return (
    <motion.div
      className="backdrop"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.img src={selectedImg} alt="enlarged pic" />
      <IconContext.Provider
        value={{ className: currentUser ? "delete-btn" : "logged-out" }}
      >
        <MdDelete onClick={handleDelete} />
      </IconContext.Provider>
    </motion.div>
  );
};

export default Modal;
