export const MenuItems = [
  {
    title: "Automation",
    path: "/products#product-1",
  },
  {
    title: "Packaging Machines",
    path: "products#product-2",
  },
  {
    title: "Material Handling Equipment",
    path: "/products#product-3",
  },
];
