import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import Typewriter from "typewriter-effect";
import hero from "../../images/herobg.jpg";
import about from "../../images/about.jpg";
import product1 from "../../images/product1.jpg";
import product2 from "../../images/product2.jpg";
import product3 from "../../images/product3.jpeg";
import ProjectCarousel from "../ProjectCarousel";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  return (
    <>
      <section className="hero">
        <img className="hero-bg" src={hero} alt="" />
        <div className="hero-text">
          <h1>
            Offering{" "}
            <span style={{ color: "#F6A41D" }}>Innovative Solutions</span> in
          </h1>
          <div className="hero-typewriter">
            <Typewriter
              options={{
                delay: 50,
                loop: true,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("Automation")
                  .pauseFor(1000)
                  .deleteAll(1)
                  .typeString("Packaging Machines")
                  .pauseFor(1000)
                  .deleteAll(1)
                  .typeString("Material Handling")
                  .pauseFor(1000)
                  .deleteAll(1)
                  .start();
              }}
            />
          </div>
          <div className="sub-text">
            <p>
              Providing creative, efficient and budget friendly solutions <br />
              for your complex engineering requirements.
            </p>
          </div>
          <Link to="/contact-us">
            <button className="avinya-button">Get a Quote</button>
          </Link>
        </div>
      </section>

      <section className="products">
        <h1> Our Products </h1>
        <div className="row gx-0 product-cards">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="card-wrapper">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-delay="300"
                data-aos-offset="100"
              >
                <div className="card">
                  <img src={product1} alt="..." />
                  <div className="card-caption">
                    <p>
                      Explore our products which provide you optimal automation
                      solutions ranging from elementary to premium automation
                      products.
                    </p>
                    <br />
                    <HashLink smooth to="products#product-1">
                      <button className="card-btn">Read More</button>
                    </HashLink>
                  </div>
                </div>
              </div>
              <div data-aos="fade" data-aos-once="true" data-aos-delay="0">
                <h3 className="card-title">Automation</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="card-wrapper">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-delay="450"
                data-aos-offset="100"
              >
                <div className="card">
                  <img src={product2} alt="..." />
                  <div className="card-caption">
                    <p>
                      Explore our products designed for providing solutions for
                      packaging of tissues, sanitary napkins, etc.
                    </p>
                    <br />
                    <HashLink smooth to="products#product-2">
                      <button className="card-btn">Read More</button>
                    </HashLink>
                  </div>
                </div>
              </div>
              <div data-aos="fade" data-aos-once="true" data-aos-delay="100">
                <h3 className="card-title">Packaging Machines</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <div className="card-wrapper">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                data-aos-delay="600"
                data-aos-offset="100"
              >
                <div className="card">
                  <img src={product3} alt="..." />
                  <div className="card-caption">
                    <p>
                      Explore our products for material handling equipments
                      designed and manufactured according to the needs of our
                      clients.
                    </p>
                    <br />
                    <HashLink smooth to="products#product-3">
                      <button className="card-btn">Read More </button>
                    </HashLink>
                  </div>
                </div>
              </div>
              <div data-aos="fade" data-aos-once="true" data-aos-delay="200">
                <h3 className="card-title">Material Handling</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about">
        <div className="row gx-0">
          <div className="col-12 col-md-6">
            <img className="about-img" src={about} alt="..." />
          </div>

          <div className="col-12 col-md-6 about-text">
            <div
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-once="true"
            >
              <h1>Who We Are</h1>
              <p>
                Avinya Automation is comprised of a group of engineering experts
                who focus on creative solutions for complex engineering
                projects. We’ve accomplished much success with various projects.
                We strive for innovation and work carefully in order to stay
                within budget while providing maximum value for our clients.
              </p>
              <div className="about-btn-wrapper">
                <Link to="/about">
                  <button className="about-btn">Read More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="projects">
        <h1>Our Projects</h1>
        <div
          data-aos="fade"
          data-aos-duration="1300"
          data-aos-delay="300"
          data-aos-once="true"
        >
          <ProjectCarousel />
        </div>
      </section>
    </>
  );
};

export default Home;
