import { React, useState, useEffect } from "react";
import "./Youtube.css";

let finalUrl = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=contentDetails&maxResults=6&playlistId=${process.env.REACT_APP_PLAYLIST_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;

function Youtube() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch(finalUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        const tempArray = responseJson.items.map(
          (obj) => "https://www.youtube.com/embed/" + obj.contentDetails.videoId
        );
        setVideos(tempArray);
      })
      .catch((error) => {
        console.error("error: " + error);
      });
  }, []);

  return (
    <>
      <div className="video-grid">
        {videos.map((link, id) => {
          var frame = (
            <div key={id} className="video">
              <iframe src={link} frameBorder="0" allowFullScreen></iframe>{" "}
            </div>
          );
          return frame;
        })}
      </div>
    </>
  );
}

export default Youtube;
