import React from "react";
import "./Products.css";
import pro1 from "../../images/pro1.png";
import pro2 from "../../images/pro2.png";
import pro3 from "../../images/pro3.png";
import useFirestore from "../../hooks/useFirestore";

const Products = () => {
  const { docs } = useFirestore("product-catalogue", "index", "asc");
  var urls = [];
  docs.map((doc) => {
    return (urls[doc.index] = doc.url);
  });

  return (
    <>
      <section className="products-intro">
        <h1 className="products-heading" id="product-1">
          Our Products
        </h1>

        <p className="products-subtext">
          At Avinya Automation, all products are manufactured with quality
          materials and carefully assembled using the latest technologies
          available.
          <br /> Browse our inventory below to get a better idea of what we have
          to offer.
        </p>
      </section>

      <section>
        <div className="row gx-0 product-description products-bg-1">
          <div className="col-md-5 col-12 product-img">
            <img src={pro1} alt=".." />
          </div>
          <div className="col-md-7 col-12 product-data">
            <div
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="250"
              data-aos-once="true"
            >
              <h1>Automation</h1>
              <p>
                We provide a wide range of products which cater to your
                industrial automation requirements. Explore our products which
                provide you optimal automation solutions ranging from elementary
                to premium automation products.
                <br />
                We provide on-site inspection and also provide solution for
                automating your industrial plant.
              </p>
              <a target="_blank" rel="noreferrer" href={urls[0]}>
                <button className="catalogue-button">
                  <span id="product-2">Catalogue</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row gx-0 product-description products-bg-2 ">
          <div className="col-md-7 col-12 product-data order-2 order-md-1">
            <div
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="250"
              data-aos-once="true"
            >
              <h1>Packaging Machines</h1>
              <p>
                We aim at providing the packaging machines used in
                pharmaceutical, logistical and paint industry etc. sectors. We
                also provide special purpose machines like tissue paper/
                Sanitary napkin packing machines or mask earloop welding
                machine. We try to understand the problem and give the best
                possible solution to profit the customer effictively. Our team,
                keeps on innovating some ideas and implement it on the machines.
              </p>
              <a target="_blank" rel="noreferrer" href={urls[1]}>
                <button className="catalogue-button">
                  <span id="product-3">Catalogue</span>
                </button>
              </a>
            </div>
          </div>
          <div className="col-md-5 col-12 product-img order-1 order-md-2">
            <img src={pro2} alt=".." />
          </div>
        </div>
      </section>

      <section>
        <div className="row gx-0 product-description products-bg-1">
          <div className="col-md-5 col-12 product-img">
            <img src={pro3} alt=".." />
          </div>
          <div className="col-md-7 col-12 product-data">
            <div
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="250"
              data-aos-once="true"
            >
              <h1>Material Handling</h1>
              <p>
                We are greately into manufacturing and developing some custom
                designed trolleys and material handling equipments as per
                customers demands. We use premium grade raw material, for
                fabricating these products, which is sourced from the leading
                vendors of the industry. We offer these at industry leading
                prices and also customize in order to meet the requirement of
                our clients.
              </p>
              <a target="_blank" rel="noreferrer" href={urls[2]}>
                <button className="catalogue-button">
                  <span>Catalogue</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
