import React from "react";
import "./Footer.css";
import {
  FaInstagram,
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { IconContext } from "react-icons";
import logo from "../icons/logo2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footer-container">
        <div className="row gx-0 gy-2 mx-auto footer-wrapper">
          <div className="col-12 col-sm-6 col-md-4 order-3 order-sm-1 column-1">
            <Link to="/">
              <div className="logo-wrapper">
                <img className="footer-logo" src={logo} alt="logo" />
                <h1>
                  <span className="avinya-brand">Avinya Automation</span>
                  <span className="tagline">Innovative minds at work!</span>
                </h1>
              </div>
            </Link>
          </div>
          <div className="col-6 col-sm-6 col-md-2 order-1 order-sm-2 column-2">
            <ul>
              <li>
                <span className="footer-title">Explore</span>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-4 order-2 order-sm-3 column-3">
            <ul>
              <li>
                <span className="footer-title">Get in Touch</span>
              </li>
              <IconContext.Provider value={{ className: "footer-icons" }}>
                <li>
                  <a href="tel:9790722190">
                    <FaPhone /> 9790722190
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:avinyaautomation01@gmail.com"
                  >
                    <FaEnvelope />
                    avinyaautomation01@gmail.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://g.page/dmc-manufacturers?share"
                  >
                    <div className="address-icon">
                      <div>
                        <FaMapMarkerAlt />
                      </div>
                      <div>
                        Shop No. 3, Triveninagar, Talawade Road, Opp. to
                        Mahanagar Bank, Pune - 411062
                      </div>
                    </div>
                  </a>
                </li>
              </IconContext.Provider>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-2 order-1 order-sm-4 column-3">
            <ul>
              <li>
                <span className="footer-title">Follow</span>
              </li>
              <IconContext.Provider
                value={{
                  className: "social-icons",
                }}
              >
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/dmc-manufacturers/"
                  >
                    <FaLinkedinIn />
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCnUKUdfMFJbZQh8faHAPm4g"
                  >
                    <FaYoutube />
                    Youtube
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/dmcmanufacturers/?hl=en"
                  >
                    <FaInstagram />
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/DMC-Manufacturers-740100459657455"
                  >
                    <FaFacebookF />
                    Facebook
                  </a>
                </li>
              </IconContext.Provider>
            </ul>
          </div>
        </div>

        <div className="row gx-0 copyright-credits">
          <div className="footer-line" />
          <div className="col-12 footer-copyright">
            <p>
              &copy;{new Date().getFullYear()}
              <span className="avinya-brand-copyright">
                {" "}
                Avinya Automation{" "}
              </span>{" "}
              <span className="credit-pipe">|</span> Made with 🖤 by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/ShamikG17"
              >
                Shamik
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/vedant-c"
              >
                {" "}
                Vedant
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
