import { React, useState } from "react";
import "./ProjectsCarousel.css";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import useFirestore from "../hooks/useFirestore";

const ProjectCarousel = () => {
  const { docs } = useFirestore("projects", "index", "asc");
  const [current, setCurrent] = useState(0);
  const length = docs.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <section className="project-carousel">
      <IoIosArrowDropleftCircle className="left-arrow" onClick={prevSlide} />
      <IoIosArrowDroprightCircle className="right-arrow" onClick={nextSlide} />

      {docs.map((slide) => {
        return (
          <div
            className={slide.index === current ? "slide active" : "slide"}
            key={slide.index}
          >
            {slide.index === current && (
              <div className="carousel-card-wrapper">
                <div className="project-card">
                  <div className="img-card">
                    <img
                      src={slide.image}
                      alt="product"
                      className="carousel-img"
                    />
                  </div>
                  <div className="carousel-card-text">
                    <p>{slide.text}</p>
                    <a target="_blank" rel="noreferrer" href={slide.catalogue}>
                      <button className="carousel-btn">Read More</button>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </section>
  );
};

export default ProjectCarousel;
