import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home";
import Products from "./components/pages/Products";
import ContactUs from "./components/pages/ContactUs";
import Gallery from "./components/pages/Gallery";
import About from "./components/pages/About";
import Footer from "./components/Footer";
import Catalogues from "./components/pages/Catalogues";
import ScrollToTop from "./components/ScrollToTop";
import LogIn from "./components/pages/LogIn";
import { AuthProvider } from "./firebase/AuthContext";
import ScrollButton from "./components/ScrollButton";

function App() {
  return (
    <>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/admin-login" component={LogIn} />
            <Route path="/products" component={Products} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/about" component={About} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/catalogues" component={Catalogues} />
          </Switch>
          <ScrollButton />
          <Footer />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
