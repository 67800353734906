import { React, useState } from "react";
import "./Gallery.css";
import Youtube from "../Youtube";
import UploadForm from "../UploadForm";
import ImageGrid from "../ImageGrid";
import Modal from "../Modal";

const Gallery = () => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  return (
    <>
      <section className="photos">
        <h1 className="gallery-heading">Gallery</h1>
        <p className="gallery-subtext">
          Take a look at our real time photos and videos of the equipments and
          special purpose machines.
        </p>
        <UploadForm />
        <ImageGrid setSelectedImg={setSelectedImg} setDeleteId={setDeleteId} />
        {selectedImg && (
          <Modal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
          />
        )}
      </section>

      <div className="line"></div>

      <section className="videos">
        <Youtube />
      </section>
    </>
  );
};

export default Gallery;
