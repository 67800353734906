import React, { useRef, useState } from "react";
import { useAuth } from "../../firebase/AuthContext";
import { useHistory } from "react-router-dom";
import "./LogIn.css";

export default function LogIn() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      await login(emailRef.current.value, passwordRef.current.value);
      history.push("/");
    } catch {
      setError("Failed to log in");
      console.log(error)
    }
  }

  return (
    <>
      <section className="login">
        <h2>Admin Log In </h2>
        <form onSubmit={handleSubmit} className="login-form">
          <label className="login-label">Email: </label>
          <input type="email" ref={emailRef} required />
          <label className="login-label">Password: </label>
          <input type="password" ref={passwordRef} required />
          <button className="login-btn">Log In</button>
        </form>
      </section>
    </>
  );
}
